import React from "react"
import {
  Document,
  Packer,
  Paragraph,
  Footer,
  Header,
  TextRun,
  Media,
  Table,
  TableCell,
  TableRow,
  BorderStyle,
  WidthType,
} from "docx"
import { saveAs } from "file-saver"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

const WordExport = props => {
  const { children, useButtonDarkTheme, customTemplate, lang } = props
  const resumeQuery = useStaticQuery(graphql`
    query($lang: String) {
      site {
        siteMetadata {
          title
        }
      }
      allPrismicResumetranslations(filter: { lang: { eq: $lang } }) {
        edges {
          node {
            data {
              certificates {
                text
              }
              contact {
                text
              }
              education {
                text
              }
              experience_skills {
                text
              }
              industries {
                text
              }
              personality {
                text
              }
              presentation {
                text
              }
              skills {
                text
              }
              work_experience {
                text
              }
              years_of_experience {
                text
              }
            }
            lang
          }
        }
      }
      allPrismicResume(filter: { lang: { eq: $lang } }) {
        edges {
          node {
            data {
              contact {
                contact_name {
                  text
                }
                email {
                  text
                }
                phone {
                  text
                }
              }
              description {
                text
                html
              }
              education {
                courses {
                  text
                }
                duration {
                  text
                }
                school {
                  text
                }
                subject {
                  text
                }
                summary {
                  text
                }
              }
              body {
                ... on PrismicResumeBodyWorkExperience {
                  primary {
                    duration {
                      text
                    }
                    employer {
                      text
                    }
                    role {
                      text
                    }
                  }
                  items {
                    client {
                      text
                    }
                    duration {
                      text
                    }
                    role {
                      text
                    }
                    summary {
                      text
                      html
                    }
                    techniques {
                      text
                    }
                  }
                }
              }
              featured_image {
                url
                dimensions {
                  height
                  width
                }
              }
              introduction {
                text
                html
              }
              name {
                text
                html
              }
              skills {
                skill {
                  text
                }
              }
              certificates {
                certificate {
                  html
                  text
                }
              }
              personality {
                personality_description {
                  text
                }
                personality_image {
                  url
                }
              }
              years_of_experience {
                text
              }
              industry {
                text
              }
            }
            lang
          }
        }
      }
    }
  `)

  const totalSkillsColumns = 6
  const fontName = "Open Sans"
  const imgWidth = 250
  const imgHeight = 250

  const location = useLocation()

  if (location.pathname === "/") return false

  const GetTranslationTexts = () => {
    let filteredByLanguage = resumeQuery.allPrismicResumetranslations?.edges.filter(
      edge => {
        return edge.node.lang === lang
      }
    )
    let translation = filteredByLanguage[0].node.data

    return {
      yearsOfExperience: translation.years_of_experience[0].text,
      industries: translation.industries[0].text,
      skills: translation.skills[0].text,
      personality: translation.personality[0].text,
      presentation: translation.presentation[0].text,
      contact: translation.contact[0].text,
      experience: translation.work_experience[0].text,
      experienceSkills: translation.experience_skills[0].text,
      education: translation.education[0].text,
      certificates: translation.certificates[0].text,
    }
  }

  const GetResume = name => {
    let resumeData = resumeQuery.allPrismicResume.edges.filter(edge => {
      return edge.node.data.name.text === name && edge.node.lang === lang
    })

    if (resumeData.length === 0) return

    const personData = resumeData[0].node.data

    const siteTitle = resumeQuery.site.siteMetadata.title
    const nameArr = personData.name?.text.split(" ")

    return {
      title: siteTitle,
      name: personData.name?.text,
      firstName: nameArr[0],
      lastName: nameArr[1],
      role: personData.description?.text,
      image: {
        alt: personData.name?.text,
        url: personData.featured_image?.url,
      },
      yearsOfExperience: personData.years_of_experience.text,
      industries: personData.industry.text,
      skills: personData.skills,
      certificates: personData.certificates,
      contact: personData.contact,
      presentation: personData.introduction,
      personality: personData.personality,
      workExperience: personData.body,
      education: personData.education,
    }
  }

  const createWordDoc = async () => {
    if (resumeQuery.allPrismicResume.edges.length === 0) return

    const name = children[0].props.title
    var resume = GetResume(name)

    if (resume === null) return

    var translation = GetTranslationTexts()
    const docName = resume.name
    let document = newBaseDocument()
    const resumeImage = await fetchResumeImage(document, resume.image.url)

    document = PopulateDocumentWithContent(
      document,
      resume,
      docName,
      resumeImage,
      translation
    )

    await exportFileAsDocx(document, docName)
  }

  const fetchResumeImage = async (doc, imgUrl) => {
    let blob = await fetch(imgUrl).then(r => r.blob())

    if (blob === null) return null

    const resumeImage = Media.addImage(doc, blob, imgWidth, imgHeight)

    return resumeImage
  }

  const newBaseDocument = () => {
    return new Document({
      creator: "",
      title: "",
      description: "",
      styles: {
        paragraphStyles: mainDocStyle(),
      },
    })
  }

  const PopulateDocumentWithContent = (
    doc,
    resume,
    docName,
    resumeImage,
    translation
  ) => {
    doc.addSection(
      presentationContent(resume, resumeImage, docName, translation)
    )
    doc.addSection(experienceContent(resume, translation))
    doc.addSection(educationContent(resume, translation.education))
    doc.addSection(certificateContent(resume, translation.certificates))

    return doc
  }

  const presentationContent = (resume, resumeImage, docName, translation) => {
    const presentationTable = getPresentationTable(resume, resumeImage, docName)
    const skills = GetSkillAsText(resume)
    return {
      headers: getHeader(),
      footers: getFooter(),
      children: [
        presentationTable,
        CreateParagraph("ResumeSkillsHeader", translation.presentation),
        CreateParagraph("ResumePresentation", resume.presentation),
        CreateParagraph("ResumeSkillsHeader", translation.skills),
        CreateParagraph("ResumePresentation", skills),
      ],
    }
  }

  const certificateContent = (resume, title) => {
    if (resume.certificates.length === 0) return

    let tableRows = []

    resume.certificates.forEach(item => {
      tableRows.push(
        new TableRow({
          children: [
            new TableCell({
              borders: noTableBorders(),
              width: { size: 100, type: WidthType.PERCENTAGE },
              children: [
                CreateParagraph("ExperienceRole", item?.certificate?.text),
              ],
            }),
          ],
        })
      )
    })

    return {
      headers: getHeader(),
      footers: getFooter(),
      children: [
        CreateParagraph("ResumeSkillsHeader", title),
        new Table({ rows: tableRows }),
      ],
    }
  }

  const GetSkillAsText = resume => {
    let skillsAsString = ""
    resume.skills.map(({ skill }) => {
      skillsAsString += `${skill.text}, `
    })
    return skillsAsString.slice(0, -2)
  }

  const experienceContent = (resume, translation) => {
    if (resume.workExperience.length === 0) return

    let tableRows = []
    resume.workExperience.map((assignment, i) => {
      assignment.items.map((experienceDetails, index) => {
        if (experienceDetails.length === 0) return

        var expEmployer = experienceDetails?.client?.text
        var expDuration = experienceDetails?.duration?.text
        var expRole = experienceDetails?.role?.text
        var expSummary = experienceDetails?.summary?.text
        var expTechniques = experienceDetails?.techniques?.text

        tableRows.push(
          new TableRow({
            children: [
              new TableCell({
                borders: noTableBorders(),
                width: { size: 100, type: WidthType.PERCENTAGE },
                children: [
                  CreateParagraph("ExperienceSecondaryCompany", expEmployer),
                  CreateParagraph("ExperienceRole", expRole),
                  CreateParagraph("DateText", expDuration),
                ],
              }),
            ],
          })
        )

        tableRows.push(
          new TableRow({
            children: [
              new TableCell({
                borders: noTableBorders(),
                width: { size: 100, type: WidthType.PERCENTAGE },
                options: { rowSpan: 2 },
                children: [CreateParagraph("ExperienceText", expSummary)],
              }),
            ],
          })
        )

        tableRows.push(
          new TableRow({
            children: [
              new TableCell({
                borders: noTableBorders(),
                width: { size: 100, type: WidthType.PERCENTAGE },
                options: { rowSpan: 2 },
                children: [
                  CreateParagraph(
                    "ExperienceToolsAndTech",
                    translation.experienceSkills
                  ),
                  CreateParagraph("ExperienceText", expTechniques),
                ],
              }),
            ],
          })
        )
      })
    })

    return {
      headers: getHeader(),
      footers: getFooter(),
      children: [
        CreateParagraph("ResumeSkillsHeader", translation.experience),
        new Table({ rows: tableRows }),
      ],
    }
  }

  const CreateParagraph = (styling, text) => {
    return new Paragraph({
      style: styling,
      children: [new TextRun(text)],
    })
  }

  const educationContent = (resume, title) => {
    if (resume.education.length === 0) return

    let tableRows = []

    resume.education.forEach(education => {
      var school = education?.school?.text
      var subject = education?.subject?.text
      var duration = education?.duration?.text

      tableRows.push(
        new TableRow({
          children: [
            new TableCell({
              borders: noTableBorders(),
              width: { size: 100, type: WidthType.PERCENTAGE },
              children: [
                CreateParagraph("ExperienceSecondaryCompany", subject),
                CreateParagraph("ExperienceRole", school),
                CreateParagraph("DateText", duration),
              ],
            }),
          ],
        })
      )
    })

    const educationTable = new Table({ rows: tableRows })

    return {
      headers: getHeader(),
      footers: getFooter(),
      children: [CreateParagraph("ResumeSkillsHeader", title), educationTable],
    }
  }

  const getPresentationTable = (resume, resumeImage, docName) => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              borders: noTableBorders(),
              width: { size: 80, type: WidthType.PERCENTAGE },
              children: [
                new Paragraph(""),
                new Paragraph(""),
                new Paragraph(""),
                CreateParagraph("ResumeName", docName),
                CreateParagraph("ResumeWorkTitle", resume.role),
              ],
            }),
            new TableCell({
              borders: noTableBorders(),
              width: { size: 20, type: WidthType.PERCENTAGE },
              children: [
                new Paragraph({
                  children: [resumeImage],
                }),
              ],
            }),
          ],
        }),
      ],
    })
  }

  const getHeader = () => {
    return {
      default: new Header({
        children: [new Paragraph("")],
      }),
    }
  }

  const getFooter = () => {
    return {
      default: new Footer({
        children: [new Paragraph("")],
      }),
    }
  }

  const exportFileAsDocx = async (doc, name) => {
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, name + ".docx")
    })
  }

  const getSkillsAsTable = resume => {
    let tableCells = []
    let tableRows = []

    resume.skills.forEach(item => {
      const skill = item?.skill?.text

      if (skill === null || skill === "") return

      var cell = new Paragraph({
        style: "ResumeSkills",
        text: skill,
      })
      var newTableCell = new TableCell({
        children: [cell],
        borders: noTableBorders(),
      })

      tableCells.push(newTableCell)

      if (tableCells.length === totalSkillsColumns) {
        tableRows.push(new TableRow({ children: tableCells }))
        tableCells = []
      }
    })

    const table = new Table({ rows: tableRows })

    return table
  }

  const mainDocStyle = () => {
    return [
      {
        id: "ResumeName",
        name: "Resume Name",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 54,
          bold: true,
          color: "black",
          font: fontName,
        },
        paragraph: {
          border: {
            top: {
              space: 100,
              value: "none",
            },
            bottom: {
              space: 40,
              value: "none",
            },
          },
        },
      },
      {
        id: "ResumeWorkTitle",
        name: "Resume Work Title",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 40,
          bold: true,
          color: "2F2F2F",
          font: fontName,
        },
        paragraph: {
          border: {
            bottom: {
              space: 24,
              value: "none",
            },
          },
        },
      },
      {
        id: "ResumePresentation",
        name: "Resume Presentation",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 28,
          bold: false,
          color: "black",
          font: fontName,
        },
        paragraph: {
          border: {
            top: {
              space: 14,
              value: "none",
            },
            bottom: {
              space: 24,
              value: "none",
            },
          },
        },
      },
      {
        id: "ResumeSkillsHeader",
        name: "Resume skill header",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 36,
          bold: true,
          color: "black",
          font: fontName,
        },
        paragraph: {
          border: {
            top: {
              space: 24,
              value: "none",
            },
            bottom: {
              space: 24,
              value: "none",
            },
          },
        },
      },
      {
        id: "ResumeSkills",
        name: "Resume skills",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 24,
          bold: false,
          color: "black",
          font: fontName,
        },
      },
      {
        id: "SkillColumn",
        name: "skill column",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
      },
      {
        id: "ExperienceMainCompany",
        name: "Resume Presentation",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 36,
          bold: true,
          color: "black",
          font: fontName,
        },
      },
      {
        id: "ExperienceSecondaryCompany",
        name: "Resume Presentation",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 30,
          bold: true,
          color: "black",
          font: fontName,
        },
        paragraph: {
          border: {
            top: {
              space: 14,
              value: "none",
            },
          },
        },
      },
      {
        id: "ExperienceRole",
        name: "Resume Presentation",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 28,
          bold: false,
          color: "black",
          font: fontName,
        },
      },
      {
        id: "ExperienceToolsAndTech",
        name: "Experience Tools And Tech",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 22,
          bold: true,
          color: "black",
          font: fontName,
        },
      },
      {
        id: "ExperienceText",
        name: "Experience Text",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 18,
          bold: false,
          color: "black",
          font: fontName,
        },
        paragraph: {
          border: {
            bottom: {
              space: 12,
              value: "none",
            },
          },
        },
      },
      {
        id: "DateText",
        name: "Date Text",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 16,
          bold: false,
          color: "black",
          font: fontName,
        },
        paragraph: {
          border: {
            bottom: {
              space: 14,
              value: "none",
            },
          },
        },
      },
    ]
  }

  const noTableBorders = () => {
    return {
      top: {
        style: BorderStyle.NONE,
        size: 0,
        color: "black",
      },
      bottom: {
        style: BorderStyle.NONE,
        size: 0,
        color: "black",
      },
      left: {
        style: BorderStyle.NONE,
        size: 0,
        color: "black",
      },
      right: {
        style: BorderStyle.NONE,
        size: 0,
        color: "black",
      },
    }
  }

  return (
    <button
      className={`${
        useButtonDarkTheme ? "border-qlblack" : "border-qlwhite"
      } button`}
      onClick={createWordDoc}
    >
      <span
        className={`${useButtonDarkTheme ? "text-qlblack" : "text-qlwhite"} "`}
      >
        Word
      </span>
    </button>
  )
}

export default WordExport
