import React from "react"
import Header from "../layout/header"

import Main from "../layout/main"

const Layout = props => {
  const { title, children, branding } = props
  return (
    <div className="page-wrapper overflow-hidden">
      <Header title={title} children={children} branding={branding}></Header>
      <Main>{children}</Main>
    </div>
  )
}

export default Layout
