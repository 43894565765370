import React from "react"
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer"

const PdfCV = ({ data }) => {
  Font.register({
    family: "OpenSans",
    fonts: [
      {
        src:
          "https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8-VQ.ttf",
        fontWeight: 200,
      },
      {
        src:
          "https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirk-VQ.ttf",
        fontWeight: 600,
      },
      {
        src:
          "https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN8rs-VQ.ttf",
        fontWeight: 800,
      },
    ],
  })

  Font.registerHyphenationCallback(word => [word])

  let employee = data?.employee

  if (employee) {
    let allExperience = []
    employee.body.forEach(assignment => {
      assignment.items.forEach(experience => {
        allExperience.push(experience)
      })
    })

    const TITLES = ["von", "van", "der", "den", "det"]

    let personData = employee
    let customTemplate = data.brand
    let translations = data.translations
    let brandingBg = customTemplate.header_color
    let brandingSecondary = "#FBF2F2"
    let brandingFont = "#4D4D4D"
    let qlwhite = "#FCFCFC"

    const styles = StyleSheet.create({
      heroPage: {
        paddingTop: "6px",
        paddingHorizontal: "6px",
        backgroundColor: "#fff",
      },
      page: {
        paddingTop: 50,
        paddingHorizontal: "30px",
        paddingBottom: 110,
        backgroundColor: "#fff",
      },
      heroHeader: {
        padding: "35px",
        width: "100%",
        height: "230px",
        backgroundColor: brandingBg,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      heroSidebar: {
        width: "230px",
        height: "518px",
        backgroundColor: brandingBg === "#f4dede" ? brandingBg : "#dadada",
        marginVertical: "6px",
        padding: "20px",
      },
      heroMain: {
        width: "347px",
        height: "518px",
        backgroundColor: qlwhite,
        marginVertical: "6px",
        marginLeft: "6px",
        padding: "20px",
      },
      header1: {
        fontFamily: "OpenSans",
        fontWeight: 800,
        fontSize: "12pt",
        textTransform: "uppercase",
        color: brandingFont,
      },
      header2: {
        fontFamily: "OpenSans",
        fontWeight: 600,
        fontSize: "11pt",
        textTransform: "uppercase",
        color: brandingFont,
        paddingBottom: "10px",
      },
      header3: {
        fontFamily: "OpenSans",
        fontWeight: 600,
        fontSize: "11pt",
        textTransform: "uppercase",
        color: brandingFont,
        paddingBottom: "10px",
        opacity: 0.6,
      },
      p: {
        fontFamily: "OpenSans",
        fontWeight: 200,
        fontSize: "12pt",
        color: brandingFont,
        width: "100%",
      },
    })

    let name = personData.name.text.split(" ")[0]
    let surname = TITLES.find(
      element => element === `${personData.name.text.split(" ")[1]}`
    )
      ? personData.name.text.split(" ")[1] +
        " " +
        personData.name.text.split(" ")[2]
      : personData.name.text.split(" ")[1]

    return (
      <Document>
        <Page size="A4" style={styles.heroPage} wrap={false}>
          <View style={styles.heroHeader}>
            <View>
              <Text
                style={{
                  fontFamily: "OpenSans",
                  fontWeight: 800,
                  fontSize:
                    name.length > 9 || surname.length > 9 ? "35pt" : "49pt",
                  textTransform: "uppercase",
                  color: qlwhite,
                  lineHeight: 1,
                }}
              >
                {name}
                {"\n"}
                {surname}
              </Text>
              <Text
                style={{
                  paddingTop: "15pt",
                  fontFamily: "OpenSans",
                  fontWeight: 200,
                  fontSize:
                    personData.description.text.length > 22 ? "20pt" : "26pt",
                  textTransform: "uppercase",
                  color: qlwhite,
                  lineHeight: 1,
                }}
              >
                {personData.description.text}
              </Text>
            </View>
            <View
              style={{
                position: "absolute",
                height: "200px",
                width: "200px",
                right: "30px",
                top: "15px",
                backgroundColor: qlwhite,
                borderTopLeftRadius: 9999,
                borderTopRightRadius: 9999,
                borderBottomRightRadius: 9999,
                borderBottomLeftRadius: 9999,
              }}
            ></View>
            <Image
              src={personData.featured_image.url}
              style={{
                width: "180px",
                height: "180px",
                position: "absolute",
                right: "40px",
                bottom: "15px",
              }}
            />
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            {/* Sidebar */}
            <View style={styles.heroSidebar}>
              {personData.years_of_experience.text !== "" &&
                translations.industries[0].text !== "" && (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        width: "50%",
                        paddingRight: "5px",
                        borderRightColor: qlwhite,
                        borderRightStyle: "solid",
                        borderRightWidth: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Text style={styles.header1}>
                        {translations.years_of_experience[0].text}
                      </Text>
                      <Text
                        style={{
                          height: "70px",
                          fontSize: "60pt",
                          fontFamily: "OpenSans",
                          fontWeight: 200,
                          color: brandingFont,
                          lineHeight: 1,
                        }}
                      >
                        {personData.years_of_experience.text}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "50%",
                        paddingLeft: "5px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Text style={styles.header1}>
                        {translations.industries[0].text}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12pt",
                          fontFamily: "OpenSans",
                          fontWeight: 200,
                          paddingTop: "10px",
													paddingLeft: "10px",
													overflowWrap: "anywhere",
													wordBreak: "break-all"
                        }}
                      >
                        {personData.industry.text}
                      </Text>
                    </View>
                  </View>
                )}

              {personData.personality[0].personality_description.text !==
                "" && (
                <View
                  style={{
                    borderTopColor: qlwhite,
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                    borderBottomColor: qlwhite,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    paddingVertical: "20px",
                    marginVertical: "10px",
                  }}
                >
                  <Text style={styles.header1}>
                    {translations.personality[0].text}
                  </Text>
                  {personData.personality.map((trait, i) => (
                    <View
                      key={`trait_${i}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      <View
                        style={{ position: "relative", marginRight: "10px" }}
                      >
                        <View
                          style={{
                            position: "relative",
                            height: "50px",
                            width: "50px",
                            backgroundColor: qlwhite,
                            borderTopLeftRadius: 9999,
                            borderTopRightRadius: 9999,
                            borderBottomRightRadius: 9999,
                            borderBottomLeftRadius: 9999,
                            opacity: 0.5,
                          }}
                        ></View>
                        <Image
                          source={trait.personality_image.url}
                          style={{
                            width: "24px",
                            height: "24px",
                            position: "absolute",
                            top: "13px",
                            left: "13px",
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontFamily: "OpenSans",
                          fontWeight: 200,
                          fontSize: "12pt",
                        }}
                      >
                        {trait.personality_description.text}
                      </Text>
                    </View>
                  ))}
                </View>
              )}

              <View style={{ marginTop: "10px" }}>
                <Text style={styles.header1}>
                  {translations.contact[0].text}
                </Text>
                {personData.contact.map((contact, i) => (
                  <View style={{ marginTop: "10px" }} key={`contact_${i}`}>
                    <Text
                      style={{
                        fontSize: "12pt",
                        fontFamily: "OpenSans",
                        fontWeight: 600,
                        color: brandingFont,
                      }}
                    >
                      {contact.contact_name.text}
                    </Text>
                    <Text
                      style={{
                        fontSize: "9pt",
                        fontFamily: "OpenSans",
                        fontWeight: 200,
                        color: brandingFont,
                        marginTop: "3px",
                      }}
                    >
                      {contact.phone.text} / {contact.email.text}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            {/* Main */}
            <View style={styles.heroMain}>
              <Text style={styles.header1}>
                {translations.presentation[0].text}
              </Text>
              <View
                style={{
                  paddingTop: "10px",
                  paddingBottom: "20px",
                  borderBottomColor: brandingSecondary,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                }}
              >
                <Text style={styles.p}>{personData.introduction.text}</Text>
              </View>
              <View
                style={{
                  paddingVertical: "20px",
                }}
              >
                <Text style={styles.header1}>
                  {translations.skills[0].text}
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingTop: "10px",
                  }}
                >
                  {personData.skills.map(({ skill }) => (
                    <Text
                      key={skill.text}
                      style={{
                        backgroundColor:
                          brandingBg === "#f4dede"
                            ? brandingSecondary
                            : "#dadada",
                        margin: "2px",
                        paddingVertical: "4px",
                        paddingHorizontal: "6px",
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                        fontFamily: "OpenSans",
                        fontWeight: 600,
                        fontSize: "10pt",
                        color: brandingFont,
                      }}
                    >
                      {skill.text}
                    </Text>
                  ))}
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "#4D4D4D",
              position: "absolute",
              bottom: "6px",
              left: "6px",
              width: "100%",
              height: "70px",
              paddingHorizontal: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            fixed
          >
            <Image
              source={customTemplate.company_logo_secondary.url}
              style={{ width: "60px", marginTop: "7px" }}
            ></Image>
            <Text
              style={{
                color: qlwhite,
                textTransform: "uppercase",
                fontFamily: "OpenSans",
                fontWeight: 600,
                fontSize: "16pt",
              }}
            >
              {customTemplate.company_url[0].text}
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page} wrap={true}>
          {/* Work experience */}

          {allExperience.map((items, i) => (
            <View wrap={false} key={`exp_${i}`}>
              {i === 0 && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: "60px",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Image
                      style={{ width: "30px", height: "30px" }}
                      src={
                        "https://images.prismic.io/queenslab-resumes/3a0635aa-7055-4697-a6fa-4dad391112e4_experience.png?auto=compress,format"
                      }
                    />
                  </View>
                  <View style={{ paddingLeft: "20px" }}>
                    <Text style={styles.header1}>
                      {translations.work_experience[0].text}
                    </Text>
                  </View>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "40px",
                }}
              >
                <View
                  style={{
                    width: "60px",
                  }}
                ></View>
                <View style={{ paddingLeft: "20px", width: "445px" }}>
                  <View wrap={false} style={{ paddingTop: "20px" }}>
                    <Text style={styles.header2}>{items.role.text}</Text>
                    <Text style={styles.header3}>
                      {items.client.text} / {items.duration.text}
                    </Text>
                    <Text style={styles.p}>{items.summary.text}</Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      paddingTop: "10px",
                    }}
                  >
                    {items.techniques.text.split(", ").length > 1 &&
                      items.techniques.text.split(", ").map((tech, i) => (
                        <Text
                          key={`exp_tech_${i}`}
                          style={{
                            backgroundColor:
                              brandingBg === "#f4dede"
                                ? brandingSecondary
                                : "#dadada",
                            margin: "2px",
                            paddingVertical: "4px",
                            paddingHorizontal: "6px",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            borderBottomLeftRadius: 5,
                            fontFamily: "OpenSans",
                            fontWeight: 600,
                            fontSize: "10pt",
                            color: brandingFont,
                          }}
                        >
                          {tech}
                        </Text>
                      ))}
                  </View>
                </View>
              </View>
            </View>
          ))}

          {/* Education */}
          {personData.education.map((items, i) => (
            <View wrap={false} key={`edu_${i}`}>
              {i === 0 && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: "60px",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <Image
                      style={{ width: "30px", height: "30px" }}
                      src={
                        "https://images.prismic.io/queenslab-resumes/c21c1891-f2bc-42c3-b65d-d361cf760e0d_education.png?auto=compress,format"
                      }
                    />
                  </View>
                  <View style={{ paddingLeft: "20px" }}>
                    <Text style={styles.header1}>
                      {translations.education[0].text}
                    </Text>
                  </View>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "40px",
                }}
              >
                <View
                  style={{
                    width: "60px",
                  }}
                ></View>
                <View style={{ paddingLeft: "20px", width: "445px" }}>
                  <View wrap={false} style={{ paddingTop: "20px" }}>
                    <Text style={styles.header2}>{items.school.text}</Text>
                    <Text style={styles.header3}>
                      {items.subject.text} / {items.duration.text}
                    </Text>
                    <Text style={styles.p}>{items.summary.text}</Text>
                  </View>
                </View>
              </View>
            </View>
          ))}
          {/* Certificates */}
          {personData.certificates[0].certificate.text !== "" &&
            personData.certificates.map((items, i) => (
              <View wrap={false} key={`cert_${i}`}>
                {i === 0 && (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "60px",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Image
                        style={{ width: "30px", height: "30px" }}
                        src={
                          "https://images.prismic.io/queenslab-resumes/fd8b865e-238e-4fe2-a820-1fef3134e231_certificate.png?auto=compress,format"
                        }
                      />
                    </View>
                    <View style={{ paddingLeft: "20px" }}>
                      <Text style={styles.header1}>
                        {translations.certificates[0].text}
                      </Text>
                    </View>
                  </View>
                )}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: "40px",
                  }}
                >
                  <View
                    style={{
                      width: "60px",
                    }}
                  ></View>
                  <View style={{ paddingLeft: "20px", width: "445px" }}>
                    <View wrap={false} style={{ paddingTop: "20px" }}>
                      <Text style={styles.p}>{items.certificate.text}</Text>
                    </View>
                  </View>
                </View>
              </View>
            ))}

          {/* Fixed footer */}
          <View
            style={{
              backgroundColor: "#4D4D4D",
              position: "absolute",
              bottom: "6px",
              left: "6px",
              width: "583px",
              height: "70px",
              paddingHorizontal: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            fixed
          >
            <Image
              source={customTemplate.company_logo_secondary.url}
              style={{ maxHeight: 70, maxWidth: 60 }}
            ></Image>
            <Text
              style={{
                color: qlwhite,
                textTransform: "uppercase",
                fontFamily: "OpenSans",
                fontWeight: 600,
                fontSize: "16pt",
              }}
            >
              {customTemplate.company_url[0].text}
            </Text>
          </View>
        </Page>
      </Document>
    )
  } else {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>Här var det inget</Text>
          </View>
        </Page>
      </Document>
    )
  }
}

export default PdfCV
