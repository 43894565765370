import React from "react"

const Main = (props) => {

  const { children } = props

  return (
    <div className="flex items-center resume-wrapper">
      <main className="flex">{children}</main>
    </div>
  )
}

export default Main
