import React, { useState, useRef } from "react"
import { useLocation } from "@reach/router"
import { Link, useStaticQuery } from "gatsby"
import useOutsideClick from "../../utils/useOutsideClick"

const upperFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const NATIVETEMPLATES = ["Queenslab", "Whitelabel"]

//TODO: resolve these from query prismic
const TEMPLATES = ["Asociety", "Consid", "Knowit", "Sigma"]

const BrandDropdown = ({ useButtonDarkTheme, brandingLabel }) => {
  const query = useStaticQuery(graphql`
    query {
      allPrismicCustomtemplate {
        edges {
          node {
            data {
              company_address {
                text
              }
              company_city {
                text
              }
              company_logo {
                url
              }
              company_logo_secondary {
                url
              }
              company_title {
                text
              }
              company_url {
                text
              }
              header_color
            }
          }
        }
      }
    }
  `)

  const allTemplates = query.allPrismicCustomtemplate.edges;
  const customTemplates = allTemplates.filter((template) => {
    const title = template.node.data.company_title[0].text;
    return !NATIVETEMPLATES.includes(upperFirst(title))
  })
  const customTemplateTitles = [...new Set(customTemplates.map(temp => upperFirst(temp.node.data.company_title[0].text)))]

  const location = useLocation()
  const ref = useRef()

  useOutsideClick(ref, () => {
    setCollapsed(true)
  })

  const [collapsed, setCollapsed] = useState(true)

  const current = location.pathname.split("/")[2]
  if (!current) return false

  const resolveLink = link => {
    const currentBranding = location.pathname.split("/")[2]
    const newURL = location.pathname.replace(
      currentBranding,
      link.toLowerCase()
    )
    return newURL
  }

  return (
    <div className="mr-4">
      <div className="inline-flex space-x-2 items-center justify-center">
        <p
          className={`text-base font-semibold leading-tight text-center ${useButtonDarkTheme ? "text-gray-700" : "text-gray-200"
            }  hidden md:block`}
        >
          {brandingLabel}
        </p>
        <div ref={ref}>
          <div className="relative inline-block text-left z-50">
            <div>
              <span className="rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={e => {
                    setCollapsed(collapsed => !collapsed)
                  }}
                >
                  {upperFirst(current)}
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div
              className={`origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg ${collapsed ? "hidden" : ""
                }`}
            >
              <div className="rounded-md bg-white shadow-xs">
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {NATIVETEMPLATES.map((link, index) => (
                    <Link key={link} to={resolveLink(link)}>
                      <div
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${index === 1 ? " border-b-2" : ""
                          }`}
                      >
                        {link}
                      </div>
                    </Link>
                  ))}
                  {customTemplateTitles.map((link, index) => (
                    <Link key={link} to={resolveLink(link)}>
                      <div
                        className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                      >
                        {link}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandDropdown
