import React, { useState, useEffect, useContext } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import PdfCV from "./../../templates/pdf"
import { appContext } from "../../context/state"
import { useLocation } from "@reach/router"

const PdfExport = ({ useButtonDarkTheme }) => {
  const [isClient, setIsClient] = useState(false)
  const context = useContext(appContext)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const location = useLocation()
  if (location.pathname === "/") return false

  let name = context?.employee?.name.text.replace(/ /g, "_")
  let title = context?.employee?.description.text.replace(/ /g, "_")
  let fileName = `${name}_(${title})_CV.pdf`

  return isClient ? <PDFDownloadLink
            document={<PdfCV data={context} />}
            fileName={fileName}
          >
            <PDFButton useButtonDarkTheme={useButtonDarkTheme}/>
          </PDFDownloadLink> : <PDFButton useButtonDarkTheme={useButtonDarkTheme}/>
            
}

export default PdfExport

const PDFButton = (useButtonDarkTheme) => (
  <div className="mr-2">
                    <button
        className={`${useButtonDarkTheme ? "text-qlblack" : "text-qlwhite"} button`}
      >
        <span
          className={useButtonDarkTheme ? "text-qlblack" : "text-qlwhite"}
          style={{ minWidth: "96px" }}
        >
          PDF
        </span>
      </button>
              
          </div>
)