import BlackLogo from "../../../content/assets/ql-logo-black.svg"
import WhiteLogo from "../../../content/assets/ql-logo-white.svg"

const defaultTemplate = "queenslab"

const LoadCustomTemplateData = (title, customTemplates, queryParameter) => {
  if (customTemplates) {
    let customTemplate = GetCustomTemplate(customTemplates, queryParameter)

    const notQueenslab =
      customTemplate?.company_title[0]?.text.toLowerCase() !==
      defaultTemplate.toLowerCase()

    return {
      logoUrl: customTemplate.company_logo.url,
      backgroundColor: customTemplate.header_color,
      useButtonDarkTheme: IsColorBright(customTemplate.header_color),
      altText: customTemplate?.company_title[0]?.text,
      city: customTemplate?.company_city[0]?.text,
      url: customTemplate?.company_url[0]?.text,
      address: customTemplate?.company_address[0]?.text,
      headerTitle: notQueenslab ? "" : title,
      printLogoHeader: notQueenslab
        ? customTemplate.company_logo.url
        : BlackLogo,
      printLogoFooter: notQueenslab
        ? customTemplate.company_logo.url
        : WhiteLogo,
    }
  }
}

const GetCustomTemplate = (customTemplates, queryParameter) => {
  const customBrandName = queryParameter?.brand
  let selectedTemplate = customTemplates.allPrismicCustomtemplate.edges.filter(
    data => {
      return (
        data.node.data.company_title[0].text?.toLowerCase() ===
        customBrandName?.toLowerCase()
      )
    }
  )

  if (selectedTemplate.length === 0) {
    selectedTemplate = customTemplates.allPrismicCustomtemplate.edges.filter(
      data => {
        return (
          data.node.data.company_title[0].text.toLowerCase() === defaultTemplate
        )
      }
    )
  }
  return selectedTemplate[0].node.data
}

export const IsColorBright = color => {
  if (color === undefined) return true

  var r, g, b, hsp

  color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"))
  r = color >> 16
  g = (color >> 8) & 255
  b = color & 255

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  return hsp > 127.5 ? true : false
}

export default LoadCustomTemplateData
