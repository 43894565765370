import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, navigate } from "gatsby"
import WordExport from "../document/wordExport"
import PdfExport from "../document/pdfExport"
import CustomTemplate from "./customTemplate"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import BlackLogo from "../../../content/assets/ql-logo-black.svg"
import WhiteLogo from "../../../content/assets/ql-logo-white.svg"
import Language from "../document/language"
import BrandDropdown from "../document/brandDropdown"

const defaultData = {
  logoUrl: "",
  backgroundColor: "",
  altText: "",
  headerTitle: "",
  useButtonDarkTheme: true,
  printLogoHeader: BlackLogo,
  printLogoFooter: WhiteLogo,
  address: "",
  city: "",
  url: "",
}

const Header = ({ title, children, branding }) => {
  const customTemplates = useStaticQuery(
    graphql`
      query {
        allPrismicCustomtemplate {
          edges {
            node {
              data {
                company_address {
                  text
                }
                company_city {
                  text
                }
                company_logo {
                  url
                }
                company_title {
                  text
                }
                company_url {
                  text
                }
                header_color
              }
            }
          }
        }
      }
    `
  )
  const location = useLocation()
  const queryParameter = (location.search &&
    queryString.parse(location.search)) || { brand: "none" }

  const [templatedData, setTemplatedData] = useState(defaultData)
  const lang = location.pathname.split("/")[1]
  useEffect(() => {
    setTemplatedData(CustomTemplate(title, customTemplates, queryParameter))
  }, [location])


  return (
    <div
      style={{
        backgroundColor: templatedData.backgroundColor,
      }}
      className="bg-qlpink flex justify-between items-center w-full p-0 pt-2 md:py-6 px-0 md:px-4 md:h-24 print:hidden"
    >
      <div className="flex flex-wrap w-full items-center">
        <div className="flex md:hidden flex-wrap">
          <div className="w-full flex justify-between items-center px-2">
            <div className="flex-grow">
              <Link to={`/`}>
                <section className="flex md:flex-row items-center">
                  <img
                    src={templatedData.logoUrl}
                    alt={templatedData.altText}
                    className="w-16 md:w-16"
                  />
                  <h4 className="hidden md:block">
                    {templatedData.headerTitle}
                  </h4>
                </section>
              </Link>
            </div>

            <PdfExport
              useButtonDarkTheme={templatedData.useButtonDarkTheme}
              isLoading={false}
            >
              {children}
            </PdfExport>

            <WordExport
              useButtonDarkTheme={templatedData.useButtonDarkTheme}
              customTemplate={templatedData}
              lang={lang}
            >
              {children}
            </WordExport>
          </div>

          <div className="w-full flex justify-between items-center mt-2">
            <BrandDropdown
              useButtonDarkTheme={templatedData.useButtonDarkTheme}
              brandingLabel={branding}
            ></BrandDropdown>
            {/*<Language />*/}
          </div>
        </div>

        <div className="hidden md:flex items-center w-full">
          <div className="flex-grow">
            <Link to={`/`}>
              <section className="flex md:flex-row items-center ">
                <img
                  src={templatedData.logoUrl}
                  alt={templatedData.altText}
                  className="h-16 md:h-10"
                  style={{ maxHeight: "2rem" }}
                />
                <h4 className="hidden md:block">{templatedData.headerTitle}</h4>
              </section>
            </Link>
          </div>

          <BrandDropdown
            useButtonDarkTheme={templatedData.useButtonDarkTheme}
            brandingLabel={branding}
          ></BrandDropdown>

          <PdfExport
            useButtonDarkTheme={templatedData.useButtonDarkTheme}
            isLoading={false}
          >
            {children}
          </PdfExport>

          <WordExport
            useButtonDarkTheme={templatedData.useButtonDarkTheme}
            customTemplate={templatedData}
            lang={lang}
          >
            {children}
          </WordExport>

          {/*<Language />*/}
        </div>
      </div>
    </div>
  )
}

export default Header
